import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';
import { IUser } from 'projects/shared/models/interfaces/user.model';
import { Subscription } from 'rxjs';
import { StaffLoginResponse } from 'projects/shared/models/interfaces/staff';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user!: StaffLoginResponse;
  subscription: Subscription = new Subscription();
  @Output() event: EventEmitter<boolean> = new EventEmitter();
  toggle = true;
  constructor(private storeChangesServ: StoreChangesService) { }

  ngOnInit(): void {
    this.getUser();
    // console.log('sssss')
  }
  getUser() {
    this.subscription.add(
      this.storeChangesServ.getUserAdmin().subscribe({
        next: (response) => {
          this.user = response;
        },
      })
    );
  }
  triggerEvent(): void {
    this.toggle = !this.toggle;
    this.event.emit(this.toggle);
  }
}
