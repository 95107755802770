import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Count } from 'projects/shared/models/order';
import { OrderService } from 'projects/shared/services/order.service';
import { w1200 } from './sidebar.resizing';
import { noop, tap } from 'rxjs';
import { TicketSystemService } from 'projects/shared/services/ticket-system.service';
import { WalletService } from 'projects/shared/services/wallet.service';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() processingOrders!: number;
  @Input() opendTickets!: number;
  sidebarCollapsed: boolean = false
  w1200: boolean = false;
  sidebarItems: any[] = [];
  currentOpenPanelId: string | null = null;
  activeParam: any;
  countOrders?: Count[];
  processingOrder!: any;
  countOfOpenTickets = 0;
  countTopups?: any[];
  activeRoute: string | undefined;

  constructor(
    private router: Router,
    private orderServ: OrderService,
    private ticketSystemService: TicketSystemService,
    private walletServ: WalletService,
    private storeChangesServ: StoreChangesService
  ) { }
  ngOnInit(): void {


    this.getActiveRouteOne()     // getActiveRouteCategoryFromCategoryModule
    this.getActiveRouteTwo()    // getActiveRouteCategoryWhenreload


    // ! if side bar collapsing => collapse all dropdowns
    document.querySelector('.sidebar-collapser')?.addEventListener('click', e => {
      let target = e.target as HTMLElement;
      if (target.classList.value.includes('pi')) target = target.closest('span')!;
      target.previousElementSibling?.querySelectorAll('.show').forEach(item => {
        setTimeout(() => {
          if (target.closest('.sidebar-collapsed')) {
            item.classList.remove('show')
          } else {
            item.classList.add('show')
          }
        }, 50);
      })
    })
    this.currentOpenPanelId = localStorage.getItem('activePanelId') || null;
    // console.log(this.currentOpenPanelId)

    // ! resize event
    window.addEventListener('resize', e => {
      if (window.innerWidth <= 1200) this.sidebarCollapsed = true;
      else this.sidebarCollapsed = false;
    })

    this.getCountOrders()
    this.getCountTopups();

    // ! detect width
    w1200.pipe(tap(
      res => {
        this.w1200 = res;
      }
    )).subscribe(noop)

    // ! get count of closed tickets and open tickets
    this.getTicketsCountByStatus();

  }
  getActiveRouteOne() {
    // getActiveRouteCategoryFromCategoryModule
    this.storeChangesServ.getActiveCategoryRoute().subscribe(active => {
      this.activeRoute = active
      // console.log(active)
    })
  }
  getActiveRouteTwo() {
    // getActiveRouteCategoryWhenreload
    const parameterPart = this.router.url.split(';')[1];
    if (parameterPart && parameterPart.includes('route=')) {
      const routeValue = parameterPart.split('route=')[1];
      this.activeRoute = routeValue
      // console.log("Route value:", routeValue);
    }

  }

  // ! get count of closed tickets and open tickets
  getTicketsCountByStatus() {
    this.ticketSystemService.getCountByStatus().pipe(tap(
      count => {
        this.countOfOpenTickets = count.open;
      }
    )).subscribe(
      noop,
      err => {

      }
    )
  }

  getCountOrders() {
    this.orderServ.countOrders('').subscribe((response) => {
      this.countOrders = response;


    });

    this.storeChangesServ.getCountOrders().subscribe(response => {
      this.countOrders = response;
    })
  }
  getCountTopups() {
    this.walletServ.countTopUps().subscribe((response) => {
      this.countTopups = response;
      // console.log(this.countTopups)
      let sum: any = 0;
    });
    this.storeChangesServ.getCountTopups().subscribe(response => {
      this.countTopups = response;
    })

  }

  toggleCollapse(panelId: string): void {
    if (this.currentOpenPanelId === panelId) {
      this.currentOpenPanelId = null;
      localStorage.removeItem('activePanelId'); // Clear if closing
    } else {
      this.currentOpenPanelId = panelId;
      localStorage.setItem('activePanelId', panelId); // Save in local storage
    }

    const lis = document.querySelectorAll('.nav.navbar-nav.categ li');
    lis.forEach(li => li.classList.remove('active'));
  }

  // Retrieve active panel ID from local storage on component initialization


  // Optional: Clear active panel ID on close if needed
  closeAllDropdowns() {
    this.currentOpenPanelId = null;
    localStorage.removeItem('activePanelId'); // Clear saved panel ID
    const lis = document.querySelectorAll('.nav.navbar-nav.categ li');
    lis.forEach(li => li.classList.remove('active'));
  }

  isMainCategoriesActive(): boolean {
    return this.activeRoute === 'mainCategories';
  }

  isCategoriesActive(): boolean {
    return this.activeRoute === 'categories';
  }

  isSubCategoryActive(): boolean {
    return this.activeRoute === 'subCategory';
  }


  activeNow(route: string): void {
    this.activeRoute = route;
  }

}
