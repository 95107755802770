<section class="layout-settings" [ngClass]="sidebarCollapsed ? 'sidebar-collapsed' : ''">
  <div class="text-center my-3 logo">
    <img src="../../../../assets/white-1.ico" alt="" width="100" />
  </div>
  <!-- ! ========================= -->
  <ul class="list-unstyled">
    <!-- ! dashboard -->
    <li routerLinkActive="active" routerLink="/" [routerLinkActiveOptions]="{exact: true}"
      (click)="closeAllDropdowns()">
      <i class="pi pi-home"></i>
      <span *ngIf="!sidebarCollapsed">Dashboard</span>
    </li>
    <!-- <li routerLinkActive="active" routerLink="/panel/categories" (click)="closeAllDropdowns()">
      <i class="pi pi-th-large"></i>
      <a *ngIf="!sidebarCollapsed">Category</a>
    </li> -->
    <!-- ! categories -->
    <li class="custom-panel" (click)="sidebarCollapsed = false">
      <a (click)="toggleCollapse('category-dropdown')"
        class="w-100 collapse-action d-flex justify-content-between align-items-center">
        <span><i class="pi pi-th-large"></i> <a *ngIf="!sidebarCollapsed">Category</a> </span>
        <span>
          <i class="pi pi-angle-right closed-icon ml-2"
            [ngClass]="currentOpenPanelId === 'category-dropdown' ? 'd-none' : 'd-block'"></i>
          <i class="pi pi-angle-down open-icon ml-2"
            [ngClass]="currentOpenPanelId !== 'category-dropdown' ? 'd-none' : 'd-block'"></i>
        </span>
      </a>
      <div id="category-dropdown" class="panel-collapse"
        [ngClass]="{ show: currentOpenPanelId === 'category-dropdown' }">
        <div class="panel-body">
          <ul class="nav navbar-nav categ">
            <li [ngClass]="{ active: isMainCategoriesActive() }" (click)="activeNow('mainCategories')"
              [routerLink]="['/panel/categories/', { route: 'mainCategories' }]">
              <a *ngIf="!sidebarCollapsed">Main Categories</a>
            </li>
            <li [ngClass]="{ active: isCategoriesActive() }" (click)="activeNow('categories')"
              [routerLink]="['/panel/categories/' , { route : 'categories'}]">
              <a *ngIf="!sidebarCollapsed"> Categories </a>
            </li>
            <li [ngClass]="{ active: isSubCategoryActive() }" (click)="activeNow('subCategory')"
              [routerLink]="['/panel/categories/' , { route : 'subCategory'}]">
              <a *ngIf="!sidebarCollapsed"> Sub Categories </a>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <!-- ! products -->
    <li routerLinkActive="active" routerLink="/panel/products" (click)="closeAllDropdowns()">
      <i class="pi pi-box"></i>
      <span *ngIf="!sidebarCollapsed">Products</span>
    </li>

    <!-- ! Users -->

    <li class="custom-panel" (click)="sidebarCollapsed = false">
      <a data-toggle="collapse" (click)="toggleCollapse('user-dropdown')"
        class="w-100 collapse-action d-flex justify-content-between align-items-center">
        <span><i class="pi pi-user"></i> <a *ngIf="!sidebarCollapsed">Users</a></span>
        <i class="pi pi-angle-right closed-icon ml-2" [ngClass]="
            currentOpenPanelId === 'user-dropdown' ? 'd-none' : 'd-block'
          "></i>
        <i class="pi pi-angle-down open-icon ml-2" [ngClass]="
            currentOpenPanelId !== 'user-dropdown' ? 'd-none' : 'd-block'
          "></i>
      </a>
      <div id="user-dropdown" class="panel-collapse" [ngClass]="{ show: currentOpenPanelId === 'user-dropdown' }">
        <div class="panel-body">
          <ul class="nav navbar-nav">
            <li routerLinkActive="active" routerLink="/panel/users/usersList">
              <a *ngIf="!sidebarCollapsed"> Users</a>
            </li>
            <li routerLinkActive="active" routerLink="/panel/users/staff">
              <a *ngIf="!sidebarCollapsed">Staff</a>
            </li>
            <li routerLinkActive="active" routerLink="/panel/users/rolesUsers">
              <a *ngIf="!sidebarCollapsed">Roles</a>
            </li>
            <li routerLinkActive="active" routerLink="/panel/users/rankUsers">
              <a *ngIf="!sidebarCollapsed">Rank</a>
            </li>
          </ul>
        </div>
      </div>
    </li>



    <!-- ! suppliers -->
    <li routerLinkActive="active" routerLink="/panel/suppliers" (click)="closeAllDropdowns()">
      <i class="pi pi-users"></i>
      <span *ngIf="!sidebarCollapsed">suppliers</span>
    </li>
    <!-- ! Orders -->
    <li class="custom-panel" (click)="sidebarCollapsed = false">
      <a (click)="toggleCollapse('order-dropdown')"
        class="w-100 collapse-action d-flex justify-content-between align-items-center position-relative">
        <span><i class="pi pi-list"></i> <a *ngIf="!sidebarCollapsed">Orders</a><span class="notification-icon"
            *ngFor="let processingOrder of countOrders">
            <i *ngIf="processingOrder._id == 'Processing' && processingOrder.count"
              class="pi pi-circle-fill text-danger "></i>
          </span> </span>
        <span>
          <i class="pi pi-angle-right closed-icon ml-2"
            [ngClass]="currentOpenPanelId === 'order-dropdown' ? 'd-none' : 'd-block'"></i>
          <i class="pi pi-angle-down open-icon ml-2"
            [ngClass]="currentOpenPanelId !== 'order-dropdown' ? 'd-none' : 'd-block'"></i>
        </span>
      </a>
      <div id="order-dropdown" class="panel-collapse h-auto"
        [ngClass]="{ show: currentOpenPanelId === 'order-dropdown' }">
        <div class="panel-body">
          <ul class="nav navbar-nav">
            <li routerLinkActive="active" routerLink="/panel/orders/ordersList">
              <a *ngIf="!sidebarCollapsed" class="position-relative"> Orders List <ng-container
                  *ngFor="let processingOrder of countOrders "><span *ngIf="processingOrder._id =='Processing'"
                    class="badge badge-danger">{{processingOrder.count}}</span> </ng-container>
              </a>
            </li>
            <li routerLinkActive="active" routerLink="/panel/orders/top-up">
              <a *ngIf="!sidebarCollapsed" class="position-relative"> Top Up <ng-container
                  *ngFor="let processingOrder of countTopups | keyvalue "><span
                    *ngIf="processingOrder.key == 'Processing'"
                    class="badge badge-danger">{{processingOrder.value}}</span> </ng-container>
              </a>
            </li>

          </ul>
        </div>
      </div>
    </li>

    <!-- ! Wallets -->
    <li class="custom-panel" (click)="sidebarCollapsed = false">
      <a data-toggle="collapse" (click)="toggleCollapse('wallet-dropdown')"
        class="w-100 collapse-action d-flex justify-content-between align-items-center">
        <span><i class="pi pi-wallet"></i> <a *ngIf="!sidebarCollapsed">Wallets</a></span>
        <i class="pi pi-angle-right closed-icon ml-2" [ngClass]="
            currentOpenPanelId === 'wallet-dropdown' ? 'd-none' : 'd-block'
          "></i>
        <i class="pi pi-angle-down open-icon ml-2" [ngClass]="
            currentOpenPanelId !== 'wallet-dropdown' ? 'd-none' : 'd-block'
          "></i>
      </a>
      <div id="wallet-dropdown" class="panel-collapse" [ngClass]="{ show: currentOpenPanelId === 'wallet-dropdown' }">
        <div class="panel-body">
          <ul class="nav navbar-nav">
            <li routerLinkActive="active" routerLink="/panel/wallets">
              <a *ngIf="!sidebarCollapsed"> Wallets List </a>
            </li>

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a *ngIf="!sidebarCollapsed"> Transaction Logs </a>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <!-- ! Tickets -->
    <li class="custom-panel" (click)="sidebarCollapsed = false">
      <a data-toggle="collapse" (click)="toggleCollapse('ticket-dropdown')"
        class="w-100 position-relative collapse-action d-flex justify-content-between align-items-center">
        <span> <i class="pi pi-ticket"></i> <a *ngIf="!sidebarCollapsed">Tickets</a><i *ngIf="countOfOpenTickets"
            class="pi pi-circle-fill text-danger notification-icon"></i></span>
        <i class="pi pi-angle-right closed-icon ml-2" [ngClass]="
            currentOpenPanelId === 'ticket-dropdown' ? 'd-none' : 'd-block'
          "></i>
        <i class="pi pi-angle-down open-icon ml-2" [ngClass]="
            currentOpenPanelId !== 'ticket-dropdown' ? 'd-none' : 'd-block'
          "></i>
      </a>
      <div id="ticket-dropdown" class="panel-collapse" [ngClass]="{ show: currentOpenPanelId === 'ticket-dropdown' }">
        <div class="panel-body">
          <ul class="nav navbar-nav">
            <li routerLinkActive="active" routerLink="/panel/ticket-system">
              <a *ngIf="!sidebarCollapsed"> Ticket System<span class="badge badge-danger">{{ opendTickets }}</span>
                <span class="rounded bg-danger px-1" *ngIf="countOfOpenTickets">{{countOfOpenTickets}}</span></a>
            </li>
            <li routerLinkActive="active" routerLink="/panel/contactUs"><a *ngIf="!sidebarCollapsed">Contact Us</a></li>
          </ul>
        </div>
      </div>

    </li>

    <!-- ! Licenses -->
    <li class="custom-panel" (click)="sidebarCollapsed = false">
      <a data-toggle="collapse" (click)="toggleCollapse('licenses-dropdown')"
        class="w-100 collapse-action d-flex justify-content-between align-items-center">
        <span> <i class="pi pi-dollar"></i> <a *ngIf="!sidebarCollapsed">Licenses</a></span>
        <i class="pi pi-angle-right closed-icon ml-2" [ngClass]="
            currentOpenPanelId === 'licenses-dropdown' ? 'd-none' : 'd-block'
          "></i>
        <i class="pi pi-angle-down open-icon ml-2"
          [ngClass]="currentOpenPanelId !== 'licenses-dropdown' ? 'd-none' : 'd-block'"></i>
      </a>
      <div id="licenses-dropdown" class="panel-collapse"
        [ngClass]="{ show: currentOpenPanelId === 'licenses-dropdown' }">
        <div class="panel-body">
          <ul class="nav navbar-nav">
            <li routerLinkActive="active" routerLink="/panel/licenses/licensesList">
              <a *ngIf="!sidebarCollapsed"> Licenses List</a>
            </li>
            <li routerLinkActive="active" routerLink="/panel/licenses/manager"
              [routerLinkActiveOptions]="{exact: true}">
              <a *ngIf="!sidebarCollapsed"> Licenses Manager</a>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <!-- ! Notification -->
    <li routerLinkActive="active" routerLink="/panel/notifications" (click)="closeAllDropdowns()">
      <i class="pi pi-bell"></i>
      <span *ngIf="!sidebarCollapsed">Notification</span>
    </li>
    <!-- ! Payment -->
    <li routerLinkActive="active" routerLink="/panel/payment" (click)="closeAllDropdowns()">
      <i class="pi pi-money-bill"></i>
      <span *ngIf="!sidebarCollapsed">Payment</span>
    </li>
    <!-- ! Reports -->
    <li routerLinkActive="active" routerLink="/panel/reports" (click)="closeAllDropdowns()">
      <i class="pi pi-file"></i>
      <span *ngIf="!sidebarCollapsed">Reports</span>
    </li>
    <li routerLinkActive="active" routerLink="/panel/sliders-center" (click)="closeAllDropdowns()">
      <i class="pi pi-sliders-h"></i>
      <span *ngIf="!sidebarCollapsed">Slider</span>
    </li>
    <!-- ! Setting -->
    <li routerLinkActive="active" routerLink="/panel/setting" (click)="closeAllDropdowns()">
      <i class="pi pi-cog"></i>
      <span *ngIf="!sidebarCollapsed">Settings</span>
    </li>
    <!-- ! Legals -->
    <li class="custom-panel" (click)="sidebarCollapsed = false">
      <a data-toggle="collapse" (click)="toggleCollapse('legal-dropdown')"
        class="w-100 collapse-action d-flex justify-content-between align-items-center">
        <span><i class="pi pi-key"></i> <a *ngIf="!sidebarCollapsed">Legals</a></span>
        <i class="pi pi-angle-right ml-2" [ngClass]="
            currentOpenPanelId === 'legal-dropdown' ? 'd-none' : 'd-block'
          "></i>
        <i class="pi pi-angle-down  ml-2" [ngClass]="
            currentOpenPanelId !== 'legal-dropdown' ? 'd-none' : 'd-block'
          "></i>
        <!-- <i class="fas fa-chevron-right closed-icon ml-2" [ngClass]="
            currentOpenPanelId === 'legal-dropdown' ? 'd-none' : 'd-block'
          "></i>
        <i class="pi pi-angle-down open-icon ml-2" [ngClass]="
            currentOpenPanelId !== 'legal-dropdown' ? 'd-none' : 'd-block'
          "></i> -->
      </a>
      <div id="legal-dropdown" class="panel-collapse" [ngClass]="{ show: currentOpenPanelId === 'legal-dropdown' }">
        <div class="panel-body">
          <ul class="nav navbar-nav">
            <li routerLinkActive="active" routerLink="/panel/FAQ">
              <i class="pi pi-question-circle"></i>
              <span *ngIf="!sidebarCollapsed">FAQ</span>
            </li>
            <li routerLinkActive="active" routerLink="/panel/blogs">
              <span *ngIf="!sidebarCollapsed">Blogs</span>
            </li>
            <li routerLinkActive="active" routerLink="/legal/privacy-policy">
              <i class="pi pi-info-circle"></i>
              <span *ngIf="!sidebarCollapsed">Privacy Policy</span>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li routerLinkActive="active" routerLink="/panel/mintanance" (click)="closeAllDropdowns()">
      <i class="pi pi-exclamation-triangle"></i>
      <span *ngIf="!sidebarCollapsed">Mintanance</span>
    </li>
  </ul>
  <!-- ! don't set anything here  -->
  <span class="position-absolute top-0 sidebar-collapser" (click)="sidebarCollapsed = !sidebarCollapsed"><i class="pi "
      [ngClass]="sidebarCollapsed ? 'pi-chevron-right' : 'pi-chevron-left'"></i></span>
  <!-- ! ========================= -->
</section>
