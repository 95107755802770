import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, map, of, } from 'rxjs';
import { environment as env } from '../environments/environment';
import { IChangePassword, ISignInResponse, IUser, IUserLogin, IUserRegister } from '../models/interfaces/user.model';
import { NavigationEnd, Router } from '@angular/router';
import { StaffLoginResponse } from '../models/interfaces/staff';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  apiURL = `${env.apiUrl}auth/`;
  apiURLStaff = `${env.apiUrl}staff/`;
  routeName: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)
  userShop: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);




  constructor(private http: HttpClient, private Router: Router) {
    this.getRouteName();

  }

  getRouteName() {
    this.Router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.routeName.next(event.url)
      });
  }

  updateUserShop(user: IUser | null) {
    this.userShop.next(user);
  }

  getUserShop(): IUser | null {
    let user: IUser | null = null;
    this.userShop.subscribe({
      next: (value) => {
        user = value
      }
    })
    return user
  }

  signInUser(user: IUserLogin): Observable<ISignInResponse> {
    return this.http.post<ISignInResponse>(`${this.apiURL}signin`, user, {
      withCredentials: true,
    });
  }

  signUp(user: IUserRegister): Observable<IUser> {
    return this.http.post<IUser>(`${this.apiURL}signup`, user, {
      withCredentials: true,
    });
  }
  // return message string
  logout() {
    sessionStorage.setItem('userExist', 'false')
    return this.http.get(`${this.apiURL}logout`, { withCredentials: true });
  }
  //return message as string
  changePassword(changePassword: IChangePassword): Observable<IUser> {
    return this.http.post<IUser>(`${this.apiURL}change-password`, changePassword, {
      withCredentials: true,
    })
  }
  forgotPassword(email: IUser): Observable<any> {
    return this.http.post<any>(`${this.apiURL}forgot-password`, email);
  }
  resetPassword(token: any, newPassword: any): Observable<IUser | any> {
    return this.http.patch<IUser>(
      `${this.apiURL}reset-password/${token}`,newPassword,{ withCredentials: true }
    );
  }
  verifyEmail(token: any): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}verify-Email/${token}`, {
      withCredentials: true,
    });
  }
  verifyID(images: any): Observable<IUser> {
    return this.http.post<any>(`${this.apiURL}verify-id`, images, {
      withCredentials: true,
    });
  }

  // return img to scan
  set2FA(queryParam = '') {
    return this.http.patch<any>(
      `${this.apiURL}set-2fa${queryParam}`,
      {},
      { withCredentials: true }
    );
  }
  // activate for first time
  activate2FA(token: any): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}activate-2fa/${token}`, {}, { withCredentials: true }
    );
  }
  // verify after login
  verify2FA(token: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}verify-2fa`, token, {
      withCredentials: true,
    })
  }
  // verify after login in Admin
  verify2FAAdmin(token: any): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}admin/validate-2fa`, token, {
      withCredentials: true,
    })
  }


  /*====================== Auth Admin and 2fa ===========================*/
  signInAdmin(user: IUserLogin): Observable<ISignInResponse> {
    return this.http.post<ISignInResponse>(`${this.apiURL}admin/login`, user, { withCredentials: true, });
  }

  // return img to scan in Admin
  set2FAAdmin(queryParam = '') {
    return this.http.post<any>(
      `${this.apiURLStaff}send-2fa${queryParam}`, {}, { withCredentials: true }
    );
  }
  // activate for first time in Admin
  activate2FAAdmin(token: any): Observable<any> {
    return this.http.patch<any>(`${this.apiURLStaff}verify-2fa/${token}`, {}, { withCredentials: true }
    );
  }
  // Disable after login in Admin
  Ddisable2FAAdmin(): Observable<any> {
    return this.http.patch<any>(`${this.apiURLStaff}disable-2fa`, {}, { withCredentials: true, })
  }
  validateAdmin(): Observable<StaffLoginResponse> {
    return this.http.get<StaffLoginResponse>(`${this.apiURLStaff}me`, { withCredentials: true })
  }
  /*============================================================================*/
  validate(): Observable<IUser> {
    return this.http.get<IUser>(`${this.apiURL}validate`, { withCredentials: true })
  }
  validateActvaite(): Observable<boolean> {
    return this.http.get<IUser>(`${this.apiURL}validate`, { withCredentials: true }).pipe(
      map(data => true),
      catchError(error => of(false))
    );
  }
  resendEmail(email: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiURL}resend-email-verivication/${email}`,
      { withCredentials: true }
    );
  }
}
