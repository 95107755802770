<div
  class="header d-flex justify-content-between align-items-center py-3"
>
  <!-- <div class="col-3">
    <button class="btn toggle-btn" (click)="triggerEvent()">
      <i class="pi pi-align-justify"></i>
    </button>
  </div> -->
  <div class="col-12 d-flex justify-content-end align-items-center">
    <i class="pi pi-search mr-3"></i>
    <i class="pi pi-bell mr-3"></i>
    <ng-template #auth>
      <a routerLink="/panel/setting/profile">
        <p-avatar
          [image]="user.avatar!"
          styleClass="mr-5"
          shape="circle"
        ></p-avatar>
      </a>
    </ng-template>
    <ng-template #notAuth>
      <button class="btn btn-primary mr-3" routerLink="/auth/login">
        Login/Register
      </button>
    </ng-template>
    <ng-container
      *ngIf="user && user._id; then auth; else notAuth"
    ></ng-container>
  </div>
</div>
