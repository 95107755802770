import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  loginMessageAfter!: string;
  endsubs$: Subject<any> = new Subject();

  constructor(
    private messageService: MessageService,
    private storeChangesServ: StoreChangesService,
  ) {}

  ngOnInit(): void {
    this.loginMessage();
  }

  ngAfterViewInit(): void {
    if (this.loginMessageAfter != '') {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: this.loginMessageAfter });
    }
  }

  loginMessage() {
    this.storeChangesServ.getloginMessage().subscribe(res => {
      console.log("res");
      this.loginMessageAfter = res
    });
  }

}
